<template>
  <div>
    <Spinner v-if="isLoading" />
    <div v-else>
      <vca-column class="short" v-for="role in allRoles" :key="role.value">
        <h4>{{ role.name }}:</h4>
        <span>{{ getAsps(role) }}</span>
      </vca-column>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "CrewAspBulkSelected",
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      allRoles: [
        {
          name: this.$t("network.roles.operation"),
          value: "operation",
        },
        { name: this.$t("network.roles.finance"), value: "finance" },
        {
          name: this.$t("network.roles.education"),
          value: "education",
        },
        { name: this.$t("network.roles.network"), value: "network" },
        {
          name: this.$t("network.roles.socialmedia"),
          value: "socialmedia",
        },
        {
          name: this.$t("network.roles.awareness"),
          value: "awareness",
        },
        { name: this.$t("network.roles.other"), value: "other" },
      ],
    };
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch("users/roles_history/list").finally(() => {
      this.isLoading = false;
    });
  },
  computed: {
    getAsps() {
      return (role) => {
        if (!this.list) {
          return "";
        }
        let userList = this.list
          .filter((entry) => {
            return entry.role == role.value;
          })
          .map((entry) => entry.user.first_name + " " + entry.user.last_name)
          .join(", ");
        return userList ? userList : " - ";
      };
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      hasPoolPermission: "user/roles/hasPoolPermission",
      crew: "crews/current",
      list: "users/roles_history/list",
    }),
  },
};
</script>
